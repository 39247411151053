@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");

.story-title-container {
    height: 220vh;
    width: 100vw;
    position: relative;
    background-color: rgb(129, 196, 202);
}

.this-is-my-story {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    height: 100vh;
    line-height: 100vh;
    text-align: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 50px;
}

.spacer {
    height: 50vh;
}

.solid-background {
    background-color: rgb(114, 158, 163);
    border: 1px rgb(114, 158, 163);
    position: relative;
    height: 70vh;
    width: 100vw;
    z-index: 3;
}