@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");

.introduction {
    width: 100vw;
    height: 100vh;
    background-color: rgb(129, 196, 202);
    position: relative;
}

.intro-text {
    position: absolute;
    bottom: 40%;
    width: 35vw;
    margin-left: 7%;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 30px;
    line-height: 40px;
}

.container {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-x: hidden;
    overflow-y: hidden;
}

.sun {
    height: 90%;
    width: 100%;
    position: absolute;
    bottom: 30%;
    left: 30%;
    object-fit: contain;
}

.clouds {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0%;
    object-fit: contain;
}

.geisel {
    height: 55%;
    width: 55%;
    position: absolute;
    bottom: 20%;
    left: 45%;
    object-fit: contain;
}

.grass {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0%;
    left: 13%;
    object-fit: contain;
}